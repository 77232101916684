import { Layout } from "../components/utilityComponents/layout";
import React, { useEffect, useState } from "react";
import { Container } from "../helpers/container";
import axios from "axios";
import { Button } from "../components/utilityComponents/button";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function ContactPage() {
  const [link, setLink] = useState("");
  const [copy, setCopy] = useState(false);
  const CLIENT_ID = "1296905374169943";
  const [REDIRECT_URI, setREDIRECT_URI] = useState("");

  const connectUser = async () => {
    const auth = await axios.get("https://api.instagram.com/oauth/authorize", {
      params: {
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        scope: "user_media",
        response_type: "code",
      },
    });

    return auth;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const auth_code = params.get("code");
    setREDIRECT_URI(`${window?.origin}/instagram`);
    if (auth_code) {
      setLink(auth_code);
    }
  }, []);

  return (
    <Layout darkBackground>
      <Container
        style={{
          display: "flex",
          minHeight: "500px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {link === "" ? (
          <a
            target={"_blank"}
            href={`https://api.instagram.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=user_profile,user_media&response_type=code`}
          >
            <Button
              label={"Połącz się z kontem instagram"}
              size={"large"}
              type={"primary"}
              onClick={() => {}}
            />
          </a>
        ) : (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <h2>Token poprawnie utworzony</h2>
            <strong>
              Skopiuj specjalny link klikając w przycisk poniżej i wklej go do
              panelu administratora w sekcji Config do pola instagramUrl
            </strong>
            <CopyToClipboard
              text={link}
              onCopy={() => {
                setCopy(true);
              }}
            >
              <Button
                label={"Skopiuj kod dostępu"}
                size={"large"}
                type={"primary"}
              />
            </CopyToClipboard>
            {copy && <p>Poprawnie skopiowano kod dostępu</p>}
          </div>
        )}
      </Container>
    </Layout>
  );
}
